import React from 'react';
import bookingData, { BookingData } from './modules/booking';
import dateNow, { DateNow } from './modules/date';
import ticketData, { TicketData } from './modules/ticket';
import otp, { OTP } from './modules/otp';

export type RootStore = {
  bookingData: BookingData;
  dateNow: DateNow;
  ticketData: TicketData;
  otp: OTP;
};

export const store: RootStore = {
  bookingData,
  dateNow,
  ticketData,
  otp
};

const StoreContext = React.createContext<RootStore>(store);

export const StoreProvider = StoreContext.Provider;

export const useStore = () => React.useContext(StoreContext);

export { dateNow, bookingData, ticketData, otp };
