export default {
  views: {
    voucher: {
      printable: '列印用憑證',
      participation: '集合或兌換地點說明',
      inclusion: '費用包含',
      restrictions: '參加限制',
      requiredAttire: '須攜帶的物品/服裝',
      requirements: '參加時必需的其他事項',
      other: '備註',
      localContact: '當地聯絡方式',
      cancellation: '取消政策',
      clickUrl: '請點擊該連結使用票券'
    }
  }
};
