export default {
  views: {
    voucher: {
      printable: '인쇄용 바우처',
      participation: '이용안내',
      inclusion: '포함내용',
      restrictions: '참가제한',
      requiredAttire: '투어 참가/바우처 교환시 지참물 및 복장',
      requirements: '필수 사항',
      other: '추가 정보',
      localContact: '현지연락처',
      cancellation: '취소정책',
      clickUrl: '티켓을 사용하려면 이 링크를 클릭하세요.'
    }
  }
};
