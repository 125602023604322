import i18n from 'i18next';
import { message } from '@linktivity/link-ui';
import { otp } from '@link/portal/stores';

const FIXED_LOCALES = ['ja'];
const IS_EN_OR_JA = FIXED_LOCALES.includes(i18n.language);

const getI18nMessages = (messageKey: string) => {
  return IS_EN_OR_JA
    ? [i18n.t(messageKey, { lng: 'ja' })].join('\n')
    : [
        i18n.t(messageKey, { lng: 'ja' }),
        i18n.t(messageKey, { lng: i18n.language })
      ].join('\n');
};

class BaseError extends Error {
  constructor(message = i18n.t('views.apiErrors.baseError')) {
    super(message);
  }

  show() {
    message.error(this.message, 10);
  }
}

export class ClientError extends BaseError {
  constructor(message = i18n.t('views.apiErrors.clientError')) {
    super(message);
  }
}

export class ServerError extends BaseError {
  constructor(message = getI18nMessages('views.apiErrors.serverError')) {
    super(message);
  }
}

export class ConnectionError extends BaseError {
  constructor(message = getI18nMessages('views.apiErrors.connectionError')) {
    super(message);
  }
}

export class RequestError extends BaseError {
  constructor(message = i18n.t('views.apiErrors.requestError')) {
    super(message);
  }
}

export class EticketError extends BaseError {
  constructor(message: string) {
    super(message);
  }
}

export class EticketOTPError extends BaseError {
  id = '';
  constructor(message: string, id: string) {
    super(message);
    this.id = id;
  }

  show() {
    switch (this.id) {
      case 'OTP_NOT_ACTIVATED': {
        otp.updateRegisterOTP(true);
        break;
      }
      case 'OTP_REQUIRED': {
        otp.updateRequestOTP(true);
        otp.updateVerifyOTP(true);
        break;
      }
      default: {
        super.show();
      }
    }
  }
}
