import { EticketPortalMixer } from '@linktivity/eticketportal';

const Language = EticketPortalMixer.Language;
type Language = EticketPortalMixer.Language;

const Country = EticketPortalMixer.Country;
type Country = EticketPortalMixer.Country;

const EticketportalmixerStatusId =
  EticketPortalMixer.EticketportalmixerStatusId;
type EticketportalmixerStatusId = EticketPortalMixer.EticketportalmixerStatusId;

const SubStatusId = EticketPortalMixer.SubStatusId;
type SubStatusId = EticketPortalMixer.SubStatusId;

const TicketTypeId = EticketPortalMixer.TicketTypeId;
type TicketTypeId = EticketPortalMixer.TicketTypeId;

const CodeType = EticketPortalMixer.CodeType;
type CodeType = EticketPortalMixer.CodeType;

const SupplierOperation = EticketPortalMixer.SupplierOperation;
type SupplierOperation = EticketPortalMixer.SupplierOperation;

export {
  Language,
  Country,
  EticketportalmixerStatusId,
  SubStatusId,
  TicketTypeId,
  CodeType,
  SupplierOperation
};
