export default {
  views: {
    dashboard: {
      title: 'ダッシュボード'
    },
    common: {
      colon: '：',
      submit: '送信',
      resend: '再送信',
      cancel: 'キャンセル',
      confirm: '確認',
      tickets: 'チケット一覧',
      copyBtn: 'URLをコピー',
      recommendBrowserTips:
        '非推奨のブラウザでアクセスしています。推奨のブラウザを利用しアクセスしてください。'
    },
    booking: {
      details: '予約詳細',
      bookingId: 'Booking {{id}}',
      history: '利用履歴'
    },
    otp: {
      title: 'ワンタイムパスワード',
      tips1:
        'お客様のアクセス環境が変わりました。 \n キュリティ保護のため、ワンタイムパスワードによる認証を行います。',
      tips2: 'ワンタイムパスワードを受け取るメールアドレスを登録してください。',

      inputOTPCode:
        'メールに記載されているワンタイムパスワードを入力してください。',
      sendCode:
        '{{email}} にワンタイムパスワードを送付しました。メールを確認してください。',
      invalidEmail: '無効なメールアドレスです。',
      inputEmail: 'メールアドレスを入力してください。',
      inputPassword: 'ワンタイムパスワードを入力してください。',
      sendOTP: 'ワンタイムパスワードを送信する'
    },
    ticket: {
      status: {
        INVALID: '無効',
        AWAITING: '利用可能期間前',
        UNUSED: '利用前',
        USABLE: '利用可能',
        IN_USE: '利用中',
        USED: '利用済み',
        EXPIRED: '期限切れ',
        DISTRIBUTED: '同行者に分配済み',
        RETURNED: '同行者への分配を返却済み'
      },
      subStatus: {
        ENTERED: '入場済み',
        EXITED: '出場済み',
        INITIAL_STATION_CANCELLED: '乗車駅キャンセル'
      },
      nowTime: '現在時刻',
      showScreenToStaff: 'この画面をスタッフにご提示ください。',
      instruction: '利用説明',
      usedAt: '利用日',
      availablePeriod: '利用可能期間',
      startUsing: '利用',
      startUsingTips: 'チケットの利用を開始しますか？',
      copyLinkSuccess: 'リンクをコピーしました。',
      second: '{{count}}秒',
      expirationDate: '有効期限'
    },
    voucher: {
      printable: '印刷用バウチャー',
      participation: '集合・引換場所の説明',
      inclusion: '含まれるもの',
      restrictions: '参加制限',
      requiredAttire: '参加時必要な服装・持ち物',
      requirements: '参加前、参加時必要事項',
      other: '備考・その他',
      localContact: '現地連絡先',
      cancellation: 'キャンセルポリシー',
      clickUrl: 'チケットを使用するにはこのリンクをクリックしてください'
    }
  },
  apiErrors: {
    baseError: 'エラーが発生しました。',
    clientError: 'クライアントからのリクエストに誤りがありました。',
    serverError: 'サーバがリクエストの処理に失敗しました。',
    connectionError:
      '接続エラーが発生しました。通信環境をご確認して、再度お試しください。',
    requestError: 'リクエストの作成に誤りがありました。'
  }
};
