/* eslint-disable react-refresh/only-export-components */
import { lazy, Suspense } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  useRouteError
} from 'react-router-dom';
import { BaseLayout } from '@link/portal/layouts';

const Booking = lazy(() => import('@link/portal/views/Booking'));
const BookingDetails = lazy(() => import('@link/portal/views/BookingDetails'));
const TicketDetails = lazy(() => import('@link/portal/views/TicketDetails'));
const Dashboard = lazy(() => import('@link/portal/views/Dashboard'));
const NotFound = lazy(() => import('@link/portal/views/NotFound'));
const VoucherDetails = lazy(() => import('@link/portal/views/VoucherDetails'));

function RootErrorBoundary() {
  const error = useRouteError() as Error;
  if (error.message.includes('Failed to fetch dynamically imported module')) {
    location.reload();
  }
  return null;
}

const lazyView = (view: JSX.Element) => {
  return <Suspense fallback="Loading...">{view}</Suspense>;
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<RootErrorBoundary />}>
      <Route>
        <Route path="/" element={<BaseLayout />}>
          <Route index element={<NotFound />} />
          <Route path="ticket/:supplierId/:secret/:bookingId">
            <Route index element={lazyView(<Booking />)} />
            <Route path="details" element={lazyView(<BookingDetails />)} />
          </Route>
          <Route path="ticket/:supplierId/:secret/:bookingId/:ticketId/:distributionId?">
            <Route index element={lazyView(<TicketDetails />)} />
          </Route>
          <Route path="dashboard" element={lazyView(<Dashboard />)} />
          <Route
            path="voucher/:bookingId/:secret"
            element={lazyView(<VoucherDetails />)}
          />
        </Route>
      </Route>

      <Route path="*" element={lazyView(<NotFound />)} />
    </Route>
  )
);

export default router;
