import { action, observable, toJS, computed } from 'mobx';
import { eticketPortal } from '@link/portal/services';
import {
  GetBookingResponse,
  GetBookingRequest
} from '@link/portal/services/types';

export class BookingData {
  @observable accessor bookingData: GetBookingResponse = {};

  @action
  getBookingData = async (body: GetBookingRequest) => {
    const bookingDate = await eticketPortal.getBooking({ body });
    if (bookingDate) {
      this.setBookingData(bookingDate);
    }
  };

  @action
  setBookingData(detail: GetBookingResponse) {
    this.bookingData = detail;
  }

  @computed
  get booking() {
    return toJS(this.bookingData.booking) || {};
  }
  @computed
  get uiSetting() {
    return toJS(this.booking.uiSetting) || {};
  }

  @computed
  get ticketSummaries() {
    return toJS(this.booking.ticketSummaries) || [];
  }

  @computed
  get externalOperations() {
    return toJS(this.bookingData.externalOperations) || [];
  }
}

export default new BookingData();
