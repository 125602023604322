import Logo from '@link/portal/assets/logo.svg?react';

import styles from './header.module.css';

const Header: React.FC = () => {
  return (
    <header className={styles.header}>
      <div className={styles.inner}>
        <Logo className={styles.logo} />
      </div>
    </header>
  );
};

export default Header;
