import { VoucherMixer } from '@linktivity/eticketportal';
import { message } from '@linktivity/link-ui';

const BASE_PATH = import.meta.env.VITE_API_VOUCHER_HOST;

const config = new VoucherMixer.Configuration({
  basePath: BASE_PATH,
  middleware: [
    {
      pre: async context => {
        const requestHeaders: HeadersInit = new Headers({
          'Content-Type': 'application/json'
        });
        context.init.headers = requestHeaders;
      },
      post: async context => {
        if (!context.response.ok) {
          // handle error
          const data = await context.response.json();
          message.error(
            data.common.error_description || data.common.error_code,
            10
          );
        }
      }
    }
  ]
});

const voucher = new VoucherMixer.VoucherMixerApi(config);

export default voucher;
