export default {
  views: {
    voucher: {
      printable: '打印用凭证',
      participation: '集合或兑换地点说明',
      inclusion: '费用包含',
      restrictions: '参加制限',
      requiredAttire: '须携带的物品/服装',
      requirements: '参加时必需的其他事项',
      other: '备注',
      localContact: '当地联络方式',
      cancellation: '取消政策',
      clickUrl: '请点击该链接使用票券'
    }
  }
};
