export default {
  views: {
    voucher: {
      printable: 'Printable Voucher',
      participation: 'Instruction for Participation',
      inclusion: 'Inclusion',
      restrictions: 'Restrictions',
      requiredAttire: 'Required items to bring & attire',
      requirements: 'Requirements',
      other: 'Other info',
      localContact: 'Local contact',
      cancellation: 'Cancellation policy',
      clickUrl: 'Please click on this link to redeem your ticket'
    }
  }
};
