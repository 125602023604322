import i18n from '@link/portal/i18n';
import { Locale } from '@link/portal/services/types';
import { LANGUAGE_MAP } from '@link/portal/services/const';
import { Country, Language } from '@link/portal/services/enum';

type SupportedLocale = keyof typeof LANGUAGE_MAP;

export function getCurrentLocale(currentLang?: string): Locale {
  const locale = (currentLang || i18n.language) as SupportedLocale;
  const lang = LANGUAGE_MAP[locale] || Language.Japanese;

  return {
    language: lang,
    country: Country.Universal
  };
}
